var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"queryView"},[_c('div',{staticClass:"pageContent-title mb-6"},[_vm._v("服务记录 - ("+_vm._s(_vm.opsLine)+" / "+_vm._s(_vm.opsType)+" / "+_vm._s(_vm.opsName)+" )")]),_c('div',{staticClass:"queryView-conditions"},[_c('a-form',{ref:"formRef",staticClass:"form-label-5",attrs:{"layout":"inline","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"业务单据号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['serviceNo']),expression:"['serviceNo']"}],attrs:{"placeholder":"请输入业务单据号"}})],1),_c('a-form-item',{attrs:{"label":"服务来源"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['serviceSource']),expression:"['serviceSource']"}],attrs:{"placeholder":"请输入服务来源"}})],1),_c('a-form-item',{attrs:{"label":"服务人员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['operationPersonnel']),expression:"['operationPersonnel']"}],attrs:{"placeholder":"请输入服务人员"}})],1),_c('a-form-item',[_c('div',{staticClass:"condition-btns"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.doQuery(1)}}},[_vm._v("查询")]),_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.resetAndQuery}},[_vm._v("重置")])],1)])],1)],1),_c('div',{staticClass:"queryView-actions"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addRow}},[_vm._v("新增执行")])],1),_c('div',{staticClass:"queryView-main"},[_c('a-table',{staticClass:"queryView-table",attrs:{"dataSource":_vm.dataSource,"columns":_vm.columns,"rowKey":_vm.rowKey,"loading":_vm.loading,"pagination":{
        current: _vm.pageNo,
        pageSize: _vm.pageSize,
        total: _vm.total,
        showSizeChanger: true,
        showQuickJumper: true,
      },"scroll":{
        x: 1300,
      },"expandIconColumnIndex":-1,"expandIconAsCell":false,"expandedRowRender":_vm.expandedRowRender,"expandedRowKeys":_vm.expandedRowKeys},on:{"update:expandedRowKeys":function($event){_vm.expandedRowKeys=$event},"update:expanded-row-keys":function($event){_vm.expandedRowKeys=$event},"change":_vm.handleTableChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }