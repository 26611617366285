<template>
  <div class="queryView">
    <div class="pageContent-title mb-6">服务记录 - ({{ opsLine }} / {{ opsType }} / {{ opsName }} )</div>
    <div class="queryView-conditions">
      <a-form layout="inline" class="form-label-5" :form="form" ref="formRef">
        <a-form-item label="业务单据号">
          <a-input v-decorator="['serviceNo']" placeholder="请输入业务单据号" />
        </a-form-item>
        <a-form-item label="服务来源">
          <a-input v-decorator="['serviceSource']" placeholder="请输入服务来源" />
        </a-form-item>
        <a-form-item label="服务人员">
          <a-input v-decorator="['operationPersonnel']" placeholder="请输入服务人员" />
        </a-form-item>

        <a-form-item>
          <div class="condition-btns">
            <a-button type="primary" @click="doQuery(1)">查询</a-button>
            <a-button type="default" @click="resetAndQuery">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div class="queryView-actions">
      <a-button type="primary" @click="addRow">新增执行</a-button>
    </div>
    <div class="queryView-main">
      <a-table
        class="queryView-table"
        :dataSource="dataSource"
        :columns="columns"
        :rowKey="rowKey"
        :loading="loading"
        :pagination="{
          current: pageNo,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          showQuickJumper: true,
        }"
        :scroll="{
          x: 1300,
        }"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        :expandedRowRender="expandedRowRender"
        :expandedRowKeys.sync="expandedRowKeys"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, getCurrentInstance } from 'vue'
import { useQueryView } from '@/hooks/useQueryView'
import { getTypeName } from '@/utils'
import moment from 'moment'
import axios from '@/plugins/axios'
export default defineComponent({
  setup() {
    const vm = getCurrentInstance().proxy
    const getListPage = async (pagination, fields) => {
      const res = await axios.post('/ops-server/opsDetails/list', {
        pageSize: pagination.pageSize,
        pageNo: pagination.pageNo,
        ...fields,
        templateNo: vm.templateNo,
      })
      return {
        dataSource: res.data.data,
        total: res.data.totalCount,
      }
    }
    const typesMap = {
      status: [
        {
          label: '通过',
          value: 2,
        },
        {
          label: '失败',
          value: 3,
        },
      ],
    }
    const formRef = ref(null)
    const { pageSize, pageNo, total, dataSource, loading, getRowKey, resetAndQuery, doQuery, handleTableChange } =
      useQueryView(formRef, {
        getListPage,
        doQueryOnMounted: false,
      })
    return {
      formRef,
      pageSize,
      pageNo,
      total,
      dataSource,

      loading,
      getRowKey,
      resetAndQuery,
      doQuery,
      handleTableChange,

      typesMap,
      getTypeName,
    }
  },
  data() {
    return {
      columns: [
        {
          title: '服务id',
          width: 120,
          dataIndex: 'businessNo',
        },
        {
          title: '业务单据号',
          width: '25%',
          dataIndex: 'serviceNo',
        },
        {
          title: '服务来源',
          dataIndex: 'serviceSource',
          width: '15%',
        },
        {
          title: '服务人员',
          width: 90,
          dataIndex: 'operationPersonnel',
        },
        {
          title: '服务日期',
          width: '15%',
          dataIndex: 'createTime',
          customRender: (text) => {
            return <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
          },
        },

        {
          title: '服务结果',
          width: 90,
          dataIndex: 'status',
          customRender: (text) => {
            return (
              <span class={{ 'text-green-500': text === 2, 'text-red-500': text === 3 }}>
                {getTypeName(text, this.typesMap.status)}
              </span>
            )
          },
        },
        {
          title: '详细内容',
          width: 90,
          customRender: (text, row) => {
            return (
              <span
                class="cursor-pointer text-blue-500"
                onClick={(e) => {
                  this.changeRowExpand(row)
                }}
              >
                查看
              </span>
            )
          },
        },
      ],
      form: this.$form.createForm(this),
      rowKey: 'id',
      expandedRowKeys: [],
      templateNo: '',
      opsName: '',
      opsLine: '',
      opsType: '',
    }
  },
  async mounted() {
    const { id } = this.$route.query
    const res = await this.$axios.post('/ops-server/opsTemplate/get', {
      id,
    })
    const { businessNo, opsName, opsLine, opsType } = res.data
    this.opsName = opsName
    this.opsLine = opsLine
    this.opsType = opsType
    this.templateNo = businessNo
    this.doQuery()
  },
  methods: {
    addRow() {
      this.$router.push({
        path: '/ops/serviceOpDetail',
        query: {
          templateId: this.$route.query.id,
        },
      })
    },
    isExpanded(row) {
      return this.expandedRowKeys.includes(row[this.rowKey])
    },
    expandedRowRender(record) {
      return (
        <div>
          <div class="flex">
            <div>请求地址：</div>
            <div>{record.serviceUrl}</div>
          </div>
          <div class="">
            <div>请求报文：</div>
            <div>{record.requsetNote}</div>
          </div>
          <div class="">
            <div>返回报文：</div>
            <div>{record.responseNote}</div>
          </div>
        </div>
      )
    },
    async changeRowExpand(row) {
      const isExpanded = this.isExpanded(row)
      let expandedRowKeys = [...this.expandedRowKeys]
      // 先展开行，显示loading
      if (!isExpanded) {
        expandedRowKeys.push(row[this.rowKey])
      } else {
        expandedRowKeys = expandedRowKeys.filter((key) => key !== row[this.rowKey])
      }
      this.expandedRowKeys = expandedRowKeys
    },
  },
})
</script>
<style lang="less"></style>
